<template>
  <div >
    <el-dialog
      style="text-align: left;"
      width="90%"
      top="5vh"
      title="List of Financers"
      :visible="isVisited"
      @open="getTableList"
      @close="onCloseDialog"
    >
    <div>
      <el-table
        ref="multipleTable"
        :data="financerList"
        v-loading="tableLoading"
        height="70vh"
        border
        :header-cell-style="HEADER_CELL_STYLE"
        :cell-style="CELL_STYLE"
        @selection-change="handleSelectionChange"
      >
        <el-table-column width="40" type="selection"></el-table-column>
        <el-table-column label="Name" min-width="150px" show-overflow-tooltip>
          <template v-slot="scope">
            <div>{{scope.row.name}}</div>
          </template>
        </el-table-column>
        <el-table-column label="Discount Rate (%)" min-width="100px" show-overflow-tooltip>
          <template v-slot="scope">
            <div>{{scope.row.discountRatePercentage}}</div>
          </template>
        </el-table-column>
        <el-table-column label="Late Charges Fee (%)" min-width="100px" show-overflow-tooltip>
          <template v-slot="scope">
            <div>{{scope.row.lateChargesPercentage}}</div>
          </template>
        </el-table-column>
        <!-- <el-table-column label="Financer Documents" min-width="100px">
          <template v-slot="scope">
            <div>{{scope.row.financerFiles}}</div>
          </template>
        </el-table-column> -->
        <el-table-column  show-overflow-tooltip label="Terms and Condition" min-width="180px">
          <template v-slot="scope">
             <div>{{scope.row.termsConditions}}</div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="handlePageSizeChange"
        @current-change="handlePageChange"
        :page-size.sync="pagination.pageSize"
        :page-sizes="[5, 10, 20, 50, 100]"
        :current-page="pagination.pageNumber"
        layout="total, sizes, prev, pager, next"
        :total="pagination.total">
      </el-pagination>
      <div class="el-dialog__footer">
        <el-button
          outline
          round
          @click="onCloseDialog"
        >
          Cancel
        </el-button>
        <el-button

          round  type="primary"
          @click="onConfirm"
        >
          Confirm
        </el-button>
      </div>
       <file-preview :visible.sync="isOpenFilePreview" :file="previewFile"></file-preview>
    </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import FilePreview from '@/components/FilePreview'
import { capitalizeFirstLetter } from '@/utils/index.js'
import { HEADER_CELL_STYLE, CELL_STYLE } from '@/constants.js'
import { getFinanceList } from '@/services/modules/finance'
export default {
  name: 'DialogLinkFinancer',
  components: { FilePreview },
  props: {
    isVisited: {
      type: Boolean,
      required: true
    },
    selectedFinancers: {
      type: Array,
      required: false
    }
  },
  computed: {
    ...mapState(['financeAccountMe'])
  },
  data () {
    return {
      HEADER_CELL_STYLE,
      CELL_STYLE,
      financerList: [],
      tableLoading: false,
      isOpenFilePreview: false,
      previewFile: {},
      selections: [],
      pagination: {
        pageSize: 50,
        pageNumber: 0,
        total: 0
      }
    }
  },
  methods: {
    capitalizeFirstLetter,
    doLayout () {
      this.$nextTick(() => {
        this.$refs.multipleTable.doLayout()
      })
    },
    handlePreview (file) {
      this.previewFile = file
      this.isOpenFilePreview = true
    },
    handleSelectionChange (val) {
      this.selections = val
    },
    getTableList (pageSize, pageNumber, conditions) {
      const query = '&sort=updatedAt desc'
      getFinanceList(pageSize = 50, pageNumber = 0, query).then(res => {
        if (res?.code === 1000) {
          this.pagination.total = res.data.totalElements
          const financers = res.data.content
          if (this?.selectedFinancers?.length > 0) {
            this.selectedFinancers.forEach((item, idx) => {
              const index = financers.findIndex(e => e.id === item.id)
              if (index !== -1) {
                financers.splice(index, 1)
              }
            })
          }
          this.financerList = financers
        }
        this.tableLoading = false
      })
    },
    onCloseDialog () {
      this.$emit('onCloseFinancerDialog')
    },
    onConfirm () {
      let arr = this.selections
      if (this?.selectedFinancers?.length > 0) {
        arr = this.selectedFinancers.concat(this.selections)
      }
      this.$emit('update:selectedFinancers', arr)
      this.$emit('onCalculateSummary')
      this.onCloseDialog()
    },
    handlePageChange (pageNumber) {
      this.getTableList(this.pagination.pageSize, pageNumber - 1)
    },
    handlePageSizeChange (pageSize) {
      this.getTableList(pageSize, 0)
    }
  }
}
</script>
<style lang="scss" scoped>
.el-dialog__footer {
    padding: 10px 10px 0 0;
    height: 100%;
}
.el-table .cell.el-tooltip {
  >div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
